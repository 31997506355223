import React, { useState } from 'react';
import data from "./data.json";
import Header from './Header';
import ToDoList from "./ToDoList";
import ToDoForm from './ToDoForm';
import button from './subHeader';


function App() {

  const [ toDoList, setToDoList ] = useState(data);

  const handleToggle = (id) => {
    let mapped = toDoList.map(task => {
      return task.id === Number(id) ? { ...task, complete: !task.complete } : { ...task};
    });
    setToDoList(mapped);
  }

  const handleFilter = () => {
    let filtered = toDoList.filter(task => {
      return !task.complete;
    });
    setToDoList(filtered);
  }

  const addTask = (userInput ) => {
    let copy = [...toDoList];
    copy = [...copy, { id: toDoList.length + 1, task: userInput, complete: false }];
    setToDoList(copy);
  }

  return (
    <div className="App">
      <Header />
      <button>Today's List</button>
      <button>Master List</button>
      <ToDoList toDoList={toDoList} handleToggle={handleToggle} handleFilter={handleFilter}/>
      <ToDoForm addTask={addTask}/>
    </div>
  );
}

export default App;



// Master Task List / For Today / Resets everyday / 
// Completed Task Page


//be able to iterate betwoeen the two lists
//add data from one list to another list
//add list to database RESTful API
